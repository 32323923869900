<template>
  <el-aside :width="isCollapse ? '64px' : '200px'">
    <div class="aside">
      <div class="toggle-button" @click="toggle">|||</div>
      <el-menu
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        router
        :unique-opened="true"
        background-color="#373f41"
        text-color="#fff"
        :collapse-transition="false"
        :default-active="activePath"
      >
        <el-menu-item index="/"  @click="saveState('/')">
          <template slot="title" >
              <i class="el-icon-menu"></i><span slot="title">首页</span>
          </template>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-help"></i>
            <span slot="title">基本信息</span>
          </template>
          <el-menu-item index="/webbase" @click="saveState('/webbase')">
            <i class="el-icon-menu"></i>web端设置
          </el-menu-item>
          <el-menu-item index="/water" @click="saveState('/water')">
            <i class="el-icon-menu"></i>水印设置
          </el-menu-item>
<!--          <el-menu-item index="/mobbase" @click="saveState('/mobbase')">-->
<!--            <i class="el-icon-menu"></i>mob设置-->
<!--          </el-menu-item>-->
        </el-submenu>
        <el-menu-item index="/category" @click="saveState('/category')">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">栏目设置</span>
          </template>
        </el-menu-item>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">内容设置</span>
          </template>
          <el-menu-item index="/content" @click="saveState('/content')">
            <i class="el-icon-menu"></i>资讯
          </el-menu-item>
          <el-menu-item index="/product" @click="saveState('/product')">
            <i class="el-icon-menu"></i>产品
          </el-menu-item>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">轮播设置</span>
          </template>
          <el-menu-item index="/label" @click="saveState('/label')">
            <i class="el-icon-menu"></i>分类
          </el-menu-item>
          <el-menu-item index="/banner" @click="saveState('/banner')">
            <i class="el-icon-menu"></i>banner
          </el-menu-item>
          <el-menu-item index="/link" @click="saveState('/link')">
            <i class="el-icon-menu"></i>友情链接
          </el-menu-item>
<!--          <el-menu-item index="/ad" @click="saveState('/ad')">-->
<!--            <i class="el-icon-menu"></i>广告-->
<!--          </el-menu-item>-->

        </el-submenu>
        <el-menu-item index="/message" @click="saveState('/message')">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">留言查看</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/access" @click="saveState('/access')">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">访问记录</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/user" @click="saveState('/user')">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">账号设置</span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
  </el-aside>
</template>
  <script>
export default {
  name: "AsideItem",
  data() {
    return {
      activePath: "",
      isCollapse: false,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      key, keyPath;
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      key, keyPath;
      // console.log(key, keyPath);
    },
    toggle() {
      this.isCollapse = !this.isCollapse;
    },
    saveState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
  created() {
    this.activePath = window.sessionStorage.getItem("activePath");
  },
};
</script>
  <style scoped lang='scss'>
.toggle-button {
  background-color: #4d575a;
  font-size: 10px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
// ::v-deep.el-submenu__title:focus,.el-submenu__title:hover {
//     // background-color: #416895;
//     // outline: 0;
//     // background-color: #ecf5ff;
// }
::v-deep .el-submenu__title {
  text-align: left;
  background-color: #373f41;
}
.el-menu-item {
  text-align: left;
}
.el-menu {
  border-right: none;
  background-color: #373f41;
}
// .el-radio-group {
//   width: 115px;
// }
.aside {
  height: calc(100vh - 60px);
  color: #fff;
}
aside{
  background-color: #373f41;
}
.aside,
  .el-submenu,
  // .el-menu-item-group,
  .el-menu-item {
  background-color: #373f41;
  color: #fff;
  span {
    color: #fff;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  color: #fff;
  background-color: #373f41;
  width: 200px;
  min-height: 400px;
}
</style>