<template>
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="资讯标题">
        <el-input v-model="form.c_title"></el-input>
      </el-form-item>
      <el-form-item label="所属栏目">
        <el-select v-model="form.cate_id"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词">
        <el-input v-model="form.c_key" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" :rows="2"  v-model="form.c_info" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="路由">
        <el-input v-model="form.c_route" placeholder="文章链接会根据路由更改"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="setRoute()">自动获取</el-button>
      </el-form-item>
      <el-form-item label="作者">
        <el-input v-model="form.c_writer"></el-input>
      </el-form-item>
      <el-form-item label="缩略图">
        <el-upload
            class="upload-demo"
            action="/api/Upload/upload.html"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="form.fileList"
            list-type="picture"
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.c_img" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="正文">
        <div style="border: 1px solid #ccc;">
          <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
          />
          <Editor
              style="height: 500px; overflow-y: hidden;"
              v-model="form.c_content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </el-form-item>

      <el-form-item label="文章链接">
        <el-input v-model="form.islink"></el-input>
      </el-form-item>
<!--      <el-form-item label="添加时间">-->
<!--        <el-date-picker-->
<!--            value-format="yyyy:MM:dd HH:mm:ss"-->
<!--            v-model="form.release_time"-->
<!--            type="date"-->
<!--            placeholder="选择日期">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
      <el-form-item label="发布时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.release_time"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.top" :min="0" :max="100" label=""></el-input-number>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.c_status">
          <el-radio-button label="0">未审核</el-radio-button>
          <el-radio-button label="1">正常</el-radio-button>
          <el-radio-button label="2">推荐</el-radio-button>
          <el-radio-button label="3">暂停</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import axios from "axios";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  name: "getContentView",
  components: { Editor, Toolbar },
  data(){
    return{

      form: {
        id: "",
        cate_id: "",
        c_title: "",
        c_img: "",
        c_key: "",
        c_writer: "",
        top: 0,
        release_time: '',
        update_time: '',
        c_route: "",
        c_info:'',
        islink: '',
        c_content: '',
        // Contentexp:[],
        fileList:[],
        c_status: 0
      },
      cateList: [],
      editor: null,
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF:{
          'uploadImage' : {
            // customUpload: this.uploadImg,
            server: '/api/Upload/uploadEditor.html',
            fieldName: 'image[]',
            maxFileSize: 2 * 1024 * 1024, // 2M
            maxNumberOfFiles: 50,
            allowedFileTypes: ['image/*'],
            timeout: 5 * 1000, // 5 秒
          },
          'uploadVideo':{
            // customUpload: this.uploadImg,
            server: '/api/Upload/editorFile.html',
            withCredentials: true,
            fieldName: 'files[]',
            maxFileSize: 200 * 1024 * 1024, // 200M
            // maxNumberOfFiles: 3,
            // allowedFileTypes: ['video/*'],
            // timeout: 15 * 1000, // 5 秒
          }
        }
      },
      mode: 'default', // or 'simple'
    }
  },
  // created() {
  //   this.getCategory();
  // },
  mounted() {
    let geturl = window.location.href;
    let geturlinfo = geturl.split('?')[1];
    let getqys = new URLSearchParams('?'+geturlinfo);
    let id = getqys.get('id');
    console.log(id);
    axios.get('/api/Category/easyList.html').then((res)=>{
      this.cateList = res.data;
    });
    if(id > 0){
      axios.get('/api/Content/getContent.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }
      });
    }

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods:{
    setRoute(){
      var input = this.form.c_title;
      var sanitizedVal = input.replace(/[^\w\s-]/g, '').replace(/[\s]/g, '-').toLowerCase();
      this.form.c_route = sanitizedVal;
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.$router.go(-1)
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.c_img = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSubmit() {
      axios({
        method:'post',
        url:'/api/Content/setContent.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          // this.$props.id = res.data.id;
          this.form.id = res.data.id;
          // this.$router.go(0);
        }
      })
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>